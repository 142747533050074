import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      video1: imageSharp(fluid: { originalName: { regex: "/thumb-video-01/" }}) {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      video2: imageSharp(fluid: { originalName: { regex: "/thumb-video-02/" }}) {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      video3: imageSharp(fluid: { originalName: { regex: "/thumb-video-03/" }}) {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
