import React, { useState } from 'react'
import { FacebookProvider, Comments } from 'react-facebook'
import Img from 'gatsby-image'

// components
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import Icon from 'src/components/UI/MarkdownIcon'
import OpenVideo from 'src/components/OpenVideo'
import playVideoOrange from 'src/assets/images/shared/play-video-orange.png'

// style
import { Wrapper, VideoSection, InvestSection, DotsDiv } from './style'

// data
import videosJSON from './data.json'
import usePageQuery from './pageQuery'

type DotsDivsProps = {
  link: string;
  thumbnail: string;
  canShow: boolean;
}

const Series: React.FC = () => {
  const videos = videosJSON
  const videoImages = usePageQuery()
  const [ carouselIndex, setCarouselIndex ] = useState(2)

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <VideoSection>
          <div className='container pb-5 pt-md-3'>
            <div className='row mb-md-5'>
              <div className='col-12 mt-2 text-center'>
                <h2 className='text-orange--extra fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-48 lh-xl-60 mb-5'>
                  Os Segredos das Carteiras Milionárias
                </h2>
              </div>
            </div>
            <DotsDiv>
              {
              videos.map((item: DotsDivsProps, index: number) => (
                <div
                  key={index}
                  className='text-center mx-2'
                  onClick={() => {
                    if (item.canShow) {
                      setCarouselIndex(index)
                    }
                  }
                }
                >
                  {item.canShow && <Img className='rounded-3' fluid={videoImages[item.thumbnail].fluid} />}
                  <div className={videos[index].canShow ? 'd-none' : 'rounded-3 default-bg'} />
                  <p className='fs-18 lh-22 text-white mt-1'>{carouselIndex === index ? 'Assistindo' : ''}</p>
                </div>
              ))
            }
            </DotsDiv>
            <div className='video-container pt-4 pb-5'>
              <div className='mb-5'>
                {videos[carouselIndex].canShow && <Img fluid={videoImages[videos[carouselIndex].thumbnail].fluid} />}
              </div>
              <div className='open-video-container'>
                <div className='video-cursor'>
                  <OpenVideo link={videos[carouselIndex].link}>
                    <img src={playVideoOrange} alt='Assistir vídeo' />
                  </OpenVideo>
                </div>
              </div>
            </div>
          </div>
        </VideoSection>

        <InvestSection className='align-items-center justify-content-center py-5'>
          <div className='container'>
            <div className='row align-items-center justify-content-center'>
              <div className='col-12 col-lg-6'>
                <h2 className='text-white fs-32 lh-36 fs-md-42 lh-md-46 fs-lg-48 lh-lg-56 mb-4 mb-md-0'>
                  Acesse ao lado as carteiras Win
                </h2>
              </div>
              <div className='col-6 col-lg-3 text-center'>
                <p className='text-white fs-18 lh-22 fw-600'>
                  Carteira Win Moderada
                </p>
                <a
                  href='https://image.comunicacao.bancointer.com.br/lib/fe2a117170640475761c77/m/1/Carteira+WIN+Moderada+-+Portfolios.pdf'
                  title='Win Moderada'
                  className='btn btn-white btn--lg text-orange--extra mt-2 rounded-2 fs-14 fw-600 text-none'
                >
                  <span className='mr-2'>Clique aqui</span>
                  <Icon width='24' height='24' icon='navigation/arrow-right' directory='v2' />
                </a>
              </div>
              <div className='col-6 col-lg-3 text-center'>
                <p className='text-white fs-18 lh-22 fw-600'>
                  Carteira Win Arrojada
                </p>
                <a
                  href='https://image.comunicacao.bancointer.com.br/lib/fe2a117170640475761c77/m/1/Carteira+WIN+Arrojada+-+Portfoolios.pdf'
                  title='Win Arrojada'
                  className='btn btn-white btn--lg text-orange--extra mt-2 rounded-2 fs-14 fw-600 text-none'
                >
                  <span className='mr-2'>Clique aqui</span>
                  <Icon width='24' height='24' icon='navigation/arrow-right' directory='v2' />
                </a>
              </div>
            </div>
          </div>
        </InvestSection>

        <div className='container py-4'>
          <FacebookProvider appId={process.env.FACEBOOK_APP_ID}>
            <Comments width='100%' href='https://inter.co/carteiras-milionarias' />
          </FacebookProvider>
        </div>
      </Layout>
    </Wrapper>
  )
}

export default Series
